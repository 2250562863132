/***mobile breakpoints***/
@mixin largeDesktop {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}
@mixin desktop {
    @media only screen and (max-width: 992px) {
        @content;
    }
}
@mixin tablet {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin phone {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin smallphone {
    @media only screen and (max-width: 500px) {
        @content;
    }
}


$breakpoint-table: 768px;
